<template>
  <!-- <div class="firstPage"> -->
  <div class="firstPage1">
    <!-- <div class="firstPage-content">
      <el-card class="firstPage-leftPart" v-loading="newsLoading">
        <div slot="header" class="clearfix">
          <b style="font-size: 20px">产业热点资讯</b>
        </div>
        <div class="firstPage-wrapper" id="newsScrollWrapper">
          <NewsListHotTwo
            :apiDataNewsList="newsListHot"
            :newsShowLine="newsShowLine"
            :hasKeyword="false"
            :page="hotNewsParams.page"
            :page_size="hotNewsParams.page_size"
            :total="hot_news_total"
            @changePage="handleChangeHotNewsPage"
          ></NewsListHotTwo>
        </div>
      </el-card>
      <el-card class="firstPage-rightPart" v-loading="patentLoading">
        <div slot="header" class="clearfix">
          <b style="font-size: 20px">产业专利舆情</b>
        </div>
        <div class="firstPage-wrapper" id="patentScrollWrapper">
          <PatentCardList
            :apiData="patent_list"
            :showModal="true"
            :total="patent_total"
            :page="patentParams.page"
            :page_size="patentParams.page_size"
            @changePage="handleChangePatentPage"
            :limit_page="10"
          ></PatentCardList>
        </div>
      </el-card>
    </div> -->
  </div>
</template>
<script>
// import api from "@/api/api";
// import cookie from "@/utils/common.js";
// import utils from "@/utils/utils.js";
// import NewsList from "@/components/NewsList.vue";
// import NewsListHot from "@/components/NewsListHot.vue";
// import NewsListHotTwo from "@/components/NewsListHotTwo.vue";
// import PatentCard from "@/components/PatentCard.vue";
// import PatentCardList from "@/components/PatentCardList.vue";

export default {
  // components: {
  //   NewsList,
  //   NewsListHot,
  //   PatentCard,
  //   PatentCardList,
  //   NewsListHotTwo,
  // },
  data() {
    return {
      hot_news_total: 0,
      hotNewsParams: {
        page: 1,
        page_size: 10,
        account: "",
        industry: "",
        company: "",
        node_id: "",
      },
      newsListHot: [],
      newsShowLine: 0,
      news_total: 0,
      patent_total: 0,
      showMoreText: "加载更多",
      newsList: [],
      newsLoading: false,
      patentLoading: false,
      patent_list: [],
      companyNewsParams: {
        page: 1,
        page_size: 10,
        account: "",
        // company_id: '',
        company: "",
        domain_id: 1,
        industry: "",
        news_type: "",
      },
      patentParams: {
        account: "",
        industry: "",
        domain_id: 1,
        company_guogao_id: "",
        page: 1,
        page_size: 10,
        filter_type: "相关公司专利",
        inventor: "",
        company_id: "",
        applicant_id: "",
      },
    };
  },
  methods: {
    // 获取热点事件
    getHotNews() {
      this.newsLoading = true;
      api
        .getHotNews(this.hotNewsParams)
        .then((res) => {
          console.log("热点事件", res);
          this.newsListHot = res.data;
          this.hot_news_total = res.total;
          this.newsLoading = false;
        })
        .catch((err) => {
          this.newsLoading = false;
        });
    },
    // 热点事件翻页
    handleChangeHotNewsPage(val) {
      this.hotNewsParams.page = val;
      this.getHotNews();
    },
    // 加载更多
    showMorePatent() {
      this.patentParams.page += 1;
      this.getIndustryPatent();
    },
    // 获取产业专利
    getIndustryPatent() {
      // this.patent_list = []
      this.patentLoading = true;
      let startTime = new Date().valueOf();
      api
        .getIndustryPatent(this.patentParams)
        .then(async (res) => {
          console.log("产业专利", res);
          // await res.data.forEach(async (r) => {
          //   this.patent_list.push(r);
          // });
          this.patent_list = res.data;
          this.patent_total = res.count;
          console.log("patent_list", this.patent_list);
          this.patentLoading = false;
          let endTime = new Date().valueOf();
          console.log(
            `%c获取产业专利：%c${(endTime - startTime) / 1000}`,
            "color: blue;font-weight:bold",
            "color: orange;font-weight:bold"
          );
          // this.patent_list = res.data
        })
        .catch((err) => {
          this.patentLoading = false;
        });
    },
    // 响应加载更多新闻
    handleChangeNewsPage(val) {
      this.companyNewsParams.page = val;
      this.getCompanyNews();
    },
    // 响应加载更多专利
    handleChangePatentPage(val) {
      this.patentParams.page = val;
      this.getIndustryPatent();
    },
    // 加载更多
    showMore() {
      this.companyNewsParams.page += 1;
      console.log("page", this.companyNewsParams.page);
      if (this.companyNewsParams.page <= 100) {
        // this.getNews();
        this.getIndustryPatent();
      }
      //  else {
      //   this.showMoreText = "已无更多";
      // }
    },
    // 对象数组根据属性去重
    unique(arr, attr) {
      const res = new Map();
      return arr.filter((a) => !res.has(a[attr]) && res.set(a[attr], 1));
    },
    // 获取产业舆情
    getCompanyNews() {
      console.log("-----------------------获取产业舆情");
      this.newsLoading = true;
      // this.companys = []
      api.getCompanyNews(this.companyNewsParams).then((res) => {
        console.log("产业舆情", res);
        this.newsList = res.data;
        this.news_total = res.count;
        this.newsLoading = false;
      });
    },
  },
  mounted() {
    this.$router.push("/category_graph");
    // cookie.setCookie("serverIndex", "1");
    // this.patentParams.account =
    //   this.companyNewsParams.account =
    //   this.hotNewsParams.account =
    //     cookie.getCookie("account");
    // // this.getCompanyNews();
    // this.getHotNews();
    // this.getIndustryPatent();
  },
  beforeDestroy() {},
};
</script>

<style lang="scss">
.firstPage {
  width: 100%;
  min-height: 90vh;
  // background-color: #f7f7f7;
  .el-card__header {
    background: #337ab7;
    color: white;
  }
  &-content {
    width: 90%;
    margin-left: 5%;
    display: flex;
    margin-top: 60px;
    justify-content: space-between;
  }
  &-wrapper {
    height: 80vh;
    // overflow-y: scroll;
  }
  &-leftPart,
  &-rightPart {
    width: 49%;
    min-height: 60vh;
    // border: 1px dashed gray;
    border-radius: 6px;
  }
  .showMore {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #333333;
    font-size: 14px;
    p {
      cursor: pointer;
      background-color: rgba(244, 244, 244, 1);
      padding: 5px 40px 5px 40px;
      &:hover {
        background-color: rgb(228, 226, 226);
      }
    }
  }
}
</style>
